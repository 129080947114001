
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "underwriters-clients-table",
  props: {
    widgetClasses: String,
    title: String,
    clients: Array,
  },
  data() {
    return {
      counter: 0 as number,
    };
  },
  created() {
    this.displayDatatable();
  },
  methods: {
    addCommaToNumber(event) {
      variables.addCommaToNumber(event);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
        });
      }, 3000);
    },
  },
});
